/* General styles for the Testimonial section */
.testimonial-section {
    padding: 50px 20px;
    background-color: #f9f9f9;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .testimonial-heading {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333;
    position: relative;
    display: inline-block;
  }
  
  .testimonial-heading::after {
    content: '';
    width: 60px;
    height: 4px;
    background-color: #55AD9B;
    display: block;
    margin: 8px auto;
  }
  
  /* Slider styles */
  .testimonial-slider {
    width: 100%;
    max-width: 800px;
    overflow: hidden;
    position: relative;
  }
  
  .testimonial-card {
    display: none;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .testimonial-card.active {
    display: block;
  }
  
  .testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
  }
  
  .testimonial-review {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;
  }
  
  .testimonial-name {
    font-size: 1.5em;
    margin-bottom: 5px;
    color: #55AD9B;
  }
  
  .testimonial-location {
    font-size: 1em;
    color: #777;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .testimonial-heading {
      font-size: 2em;
    }
  
    .testimonial-review {
      font-size: 1em;
    }
  
    .testimonial-name {
      font-size: 1.3em;
    }
  
    .testimonial-location {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    .testimonial-heading {
      font-size: 1.8em;
    }
  
    .testimonial-review {
      font-size: 0.9em;
    }
  
    .testimonial-name {
      font-size: 1.1em;
    }
  
    .testimonial-location {
      font-size: 0.8em;
    }
  }
  