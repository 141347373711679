.hero {
    margin: 0px 100px;
    margin-top: 140px;
    height: 100%;
    padding-bottom: 10rem;

    @media(max-width:460px) {
      margin: 10px ;
      margin-top: 140px;
      
    }
  }
  
  .hero-text {
    color: #fff;
    font-size: 40px;
    font-weight: 500;
    line-height: 65px; /* Adjusted to fit the new heading */
    @media(max-width:460px) {
      font-size: 30px;
   
    }
  }
  
  .hero-text h1 {
    margin-bottom: 20px;

  }
  
  .hero-text p {
    font-size: 20px;
  }
  
  .hero-explore {
    display: flex;
    align-items: center;
    gap: 50px;
    width: fit-content;
    border: 2px solid #fff;
    margin-top: 70px;

    padding-left: 30px;
    border-radius: 60px;
    background-color: transparent;
    cursor: pointer;
    z-index: 1; /* Changed z-index to 1 to make sure it's above other elements */
    text-decoration: none;
    color: inherit;
    transition: all 0.5s ease-in-out;
  }
  .hero-explore:hover {
    background-color: #fff;
   
  }
  .hero-explore:hover p {
   color: #000;
   
  }
  .hero-explore:hover svg {
    color: #000;
  }
  
  
  .hero-explore p {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    display: inline;
    margin-right: 5px;
  }

  
  .hero-explore svg {
    font-size: 1.2em;
    color: #fff;
    vertical-align: middle;
    z-index: 1;
    margin-right: 20px;
  }
  
  .hero-dot-play {
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
  }
  
  .hero-dots {
    display: flex;
    align-items: center;
    gap: 23px;
    list-style: none;
  }
  
  .hero-dot {
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 7.5px;
    cursor: pointer;
  }
  
  .hero-dot.orange {
    background-color: #55AD9B;
  }
  
  .hero-play {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .hero-play img {
    width: 54px;
    height: 54px;
    cursor: pointer;
    animation: breathing 2s infinite;
  }
  
  .hero-play p {
    color: #fff;
    font-size: 18px;
    margin-left: 10px;
  }
  
  @keyframes breathing {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  