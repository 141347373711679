body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
*,*::after, *::before {
  box-sizing: border-box;
}