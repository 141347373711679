.swiper {
  width: 300px;
  height: 300px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-container {
  padding: 200px 50px;
  background-color: #f8f9fa;
}

.swiper-heading {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #343a40;
  text-align: center;
}

.swiper-heading::after {
  content: '';
  width: 60px;
  height: 4px;
  background-color: #55AD9B;
  display: block;
  margin: 8px auto;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  margin: auto;
  display: block;
  max-width: 90%;
  max-height: 80%;
}

.close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
