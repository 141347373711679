@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.nav {
  position: relative;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 100px;

  transition: background-color 0.3s ease;

}

.nav.scrolled {
  position: fixed;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(67, 67, 67, 0.8));
  background: linear-gradient(45deg, #000000, #434343);

  padding: 5px 100px;
}

a {
  text-decoration: none;
  color: white;
}

.nav-logo {
  display: flex;
  gap: 5px;
  background-color: #fff;
  padding: 10px;
  border-top-right-radius: 15px;
border-bottom-left-radius: 15px;

}
.logo {
  width: 6rem;
  height: 4.5rem;

}

li {
  list-style-type: none;
  color: white;
  cursor: pointer;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  font-size: 18px;
  gap: 90px;
}
.logo-text-container {
  letter-spacing: 1px;
  display: flex;
  color: #957515;
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin-top: 10px;

}

.logo-p1 {
  font-size: 1rem;
  margin-right: 5px;



}

.logo-p2 {
  font-size: 1rem;

}

.nav-menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.nav-menu-icon .bar {
  height: 3px;
  width: 25px;
  background-color: white;
  margin: 3px 0;
}

.nav-menu.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 110px;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
}

.nav-menu.open li {
  padding: 10px;
}

.nav-menu li {
  padding: 0 15px;
}

.nav-menu li:hover {
  color: #55AD9B;
}

.nav-menu li.active {
color: black;
background-color: #fff;
padding: 5px 20px;
border-top-right-radius: 15px;
border-bottom-left-radius: 15px;
}

@media (max-width: 1300px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    padding:10px 5px ;
  }
  .nav {
    padding: 5px 10px !important ;
  }

  .nav-menu-icon {
    display: flex;
  }

  .nav-menu.open {
    display: flex;
  }

  .nav-menu li {
    padding: 10px;
  }
}
