/* Footer Styles */
.footer {
    position: relative;
    background: linear-gradient(45deg, #000000, #434343);
    color: white;
    padding: 20px 20px;
    text-align: center;
  }
  
  .footer-gradient {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(67, 67, 67, 0.8));
    padding: 5px 0;
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .footer-section {
    margin: 20px 0;
  }
  
  .footer-section h3 {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .footer-section p {
    margin: 5px 0;
    font-size: 16px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .social-icons a {
    color: white;
    font-size: 24px;
    transition: transform 0.3s ease, color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #00B2EB;
    transform: scale(1.2);
  }
  
  .footer-bottom {
    margin-top: 30px;
    font-size: 14px;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      margin: 10px 0;
    }
  
    .footer-section h3 {
      font-size: 20px;
    }
  
    .footer-section p {
      font-size: 14px;
    }
  
    .social-icons a {
      font-size: 20px;
    }
  }
  